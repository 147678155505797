import { ProfileImage } from "./ProfileImage";
import { ProfileInfo } from "./ProfileInfo";
import "./ProfileTitle.css";

export function ProfileTitle() {
  return (
    <div className="profile-title">
      <ProfileImage />
      <ProfileInfo />
    </div>
  );
}
