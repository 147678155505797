import "./App.css";
import { ProfileBody } from "./components/ProfileBody";
import { ProfileTitle } from "./components/ProfileTitle";

function App() {
  return (
    <div className="App">
      <div className="mainContent">
        <ProfileTitle />
        <ProfileBody />
      </div>
    </div>
  );
}

export default App;
