import "./ProfileBody.css";
import noteIcon from "../statics/images/note.svg";
import calendarIcon from "../statics/images/calendar.svg";

function onClickResume() {
  window.open(
    "https://blog.juwoong.me/assets/statics/juwoongbae-resume-eng.pdf",
    "_blank"
  );
}

function onClickMeeting() {
  window.open("https://cal.com/juwoong", "_blank");
}

export function ProfileBody() {
  return (
    <div className="profile-body">
      <button className="profile-button" onClick={onClickResume}>
        <img src={noteIcon} className="profile-button-logo" alt="note" />
        <p>Resume(Eng)</p>
      </button>
      <button className="profile-button" onClick={onClickMeeting}>
        <img
          src={calendarIcon}
          className="profile-button-logo"
          alt="calendar"
        />
        <p>Book a meeting</p>
      </button>
    </div>
  );
}
