import "./ProfileInfo.css";
import xLogo from "../statics/logo/x.svg";
import githubLogo from "../statics/logo/github.svg";
import linkedinLogo from "../statics/logo/linkedin.svg";
import blogLogo from "../statics/logo/blog.svg";

export function ProfileInfo() {
  return (
    <div className="profile-info">
      <div className="profile-header">
        <p className="profile-name">Juwoong Bae</p>
        <p className="external-link">
          <a href="https://x.com/juwoongbae">
            <img src={xLogo} alt="x" className="profile-logo" />
          </a>
          <a href="https://github.com/juwoong">
            <img src={githubLogo} alt="github" className="profile-logo" />
          </a>
          <a href="https://www.linkedin.com/in/juungbae/">
            <img src={linkedinLogo} alt="linkedin" className="profile-logo" />
          </a>
          <a href="https://blog.juwoong.me/">
            <img src={blogLogo} alt="blog" className="profile-logo" />
          </a>
        </p>
      </div>
      <p className="profile-description">
        Software Developer with a Keen Interest in <br id="br-only-desktop" />
        Building Products and Learning Itself.
      </p>
    </div>
  );
}
